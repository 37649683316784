import { errorToast } from "../components/Toasts/Toasts";
import ApiConstants from "../utils/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const transactions = async (transactionData) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.TRANSACTION_ADD}`, transactionData);
    return response;
  } catch (error) {
    errorToast("Could not fetch records")
    throw error
  }
};

export const transactionRequest = async (amount) => {
  try{
    const response = await axios.post(`${baseUrl}/${ApiConstants.TRANSACTION_REQUEST}`, amount);
    return response;
  } catch (error) {
    errorToast("Could not fetch records")
    throw error
  }
}

export const transactionUpdate = async (data) => {
  try{
    const response = await axios.put(`${baseUrl}/${ApiConstants.TRANSACTION_REQUEST}/${data?.transctionId}`, {credit_status_type_id: data?.credit_status_type_id});
    return response;
  } catch (error) {
    errorToast(error?.response?.data?.error)
  }
}

export const transactionFilters = async (filterData) => {
  try {
    let url = `${baseUrl}/${ApiConstants.GET_TRANSACTION_FILTER}?page=${filterData?.page}&size=${filterData?.size}`;
    const queryParams = new URLSearchParams(filterData?.filterData).toString();

    if (queryParams) {
      url += `&${queryParams}`;
    }

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    errorToast("Could not fetch records")
    throw error
  }
};

export const topUpFilter = async (filterData, page, size) => {
  try{
    let url = `${baseUrl}/${ApiConstants.GET_TOP_UP_FILTER}?page=${page}&size=${size}`
    const queryParams = new URLSearchParams(filterData).toString();

    if (queryParams) {
      url += `&${queryParams}`;
    }

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error
  }
}

export const topUpSummary = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.GET_TRANSACTION_CONVERT_CURRENCY}`, data);
    return response?.data;
  } catch (error) {
    throw error
  }
}

export const topUpRequest = async(data) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.TRANSACTIONS_TOP_UP_REQUEST}`, data);
    return response;
  } catch (error) {
    throw error
  }
}

export const fetchFinancialSummary = async (id) => {
  try {
    let response = await axios.get(`${baseUrl}/${ApiConstants.TRANSACTIONS_FINANCIAL_SUMMARY}?dateRange=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
}

export const fetchCurrency = async (id) => {
  try {
    let response = await axios.get(`${baseUrl}/${ApiConstants.TRANSACTIONS_FLASH_CARDS_CURRENCY}`);
    return response;
  } catch (error) {
    throw error
  }
}

export const fetchProductSelect = async (page, size) => {
  try {
    let response = await axios.get(`${baseUrl}/${ApiConstants.PRODUCTS_RETAILER}?page=${page}&size=${size}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getReport = async (data) => {
  try {
    let response = await axios.get(`${baseUrl}/${ApiConstants.GET_REPORT}?type=${data?.type}&page=${data?.page}&size=${data?.size}&billing_type_id=${data?.billing_type}`, {  responseType: "arraybuffer" })
    return response;
  } catch (error) {
    throw error;
  }
}

export const fetchUserTransaction = async ({id,page,size}) => {
  try {
    let response = await axios.get(`${baseUrl}/${ApiConstants.GET_TRANSACTION_FILTER}/user/${id}?page=${page}&size=${size}`);
    return response;
  } catch (error) {
    throw error
  }
}

export const getTransactionReport = async (filteredData) => {
  try {
    const queryParams = new URLSearchParams(filteredData).toString();

    const response = await axios.get(`${baseUrl}/${ApiConstants.EXPORT_LOG_TRANSACTION}?${queryParams}`, {
      responseType: "arraybuffer",
      headers: {
        "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};