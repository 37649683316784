import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { postAdminCustomerFilterApi, setFilteredChips, setInitAdminCustomerDetails } from "../../../redux/features/adminCustomerSlice";
import { editCustomer } from "../../../services/AdminCustomer";
import { errorToast, successToast } from "../../Toasts/Toasts";
import InfiniteScroll from "react-infinite-scroll-component";
import { chipName } from "../../../utils/sharedFunctions";
import { clearFilteredChips } from "../../../redux/features/adminCustomerSlice";

const CustomersGrid = ({ setClearFilterValues, currentSize, setCurrentSize }) => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  const [loadingForScrollbar, setLoadingForScrollbar] = useState(false);
  const dispatch = useDispatch();
  let page = 1;

  useEffect(() => {
    try {
      dispatch(postAdminCustomerFilterApi({ filteredData: {}, page, size: currentSize }));

      return () => {
        // This will run when the component is unmounted
        dispatch(clearFilteredChips());
        dispatch(setInitAdminCustomerDetails());
      };
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }

    // eslint-disable-next-line
  }, []);
  const filteredChips = useSelector((state) => state.adminCustomer.filteredChips);

  const dataToBeFiltered = Object.fromEntries(filteredChips);
  // eslint-disable-next-line
  let transformedDataToBeFiltered = {};

  for (let key in dataToBeFiltered) {
    if (typeof dataToBeFiltered[key] === "object" && "label" in dataToBeFiltered[key] && "value" in dataToBeFiltered[key]) {
      transformedDataToBeFiltered[key] = dataToBeFiltered[key].value;
    } else {
      transformedDataToBeFiltered[key] = dataToBeFiltered[key];
    }
  }

  const adminCustomerDetails = useSelector((state) => state.adminCustomer.adminCustomerDetails);

  const handleCheckboxChange = async (event, adminCustomerDetail) => {
    try {
      let customerStatus = {
        is_active: event.target.checked,
      };

      const response = await editCustomer(adminCustomerDetail?.id, customerStatus);
      if (response?.success) {
        dispatch(postAdminCustomerFilterApi({ filteredData: transformedDataToBeFiltered, page, size: currentSize }));
        successToast(response?.message);
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };
  const handleChipRemove = (key) => {
    // Remove the chip from the filteredChips state
    const updatedChips = filteredChips.filter(([existingKey]) => existingKey !== key);

    // Dispatch the updated chips to the Redux store
    dispatch(setFilteredChips(updatedChips));

    // Call the clearFilterValues function to clear the corresponding state values
    setClearFilterValues(key);

    const convertedChips = Object.fromEntries(updatedChips);

    let transformedChips = {};

    for (let key in convertedChips) {
      if (typeof convertedChips[key] === "object" && "label" in convertedChips[key] && "value" in convertedChips[key]) {
        transformedChips[key] = convertedChips[key].value;
      } else {
        transformedChips[key] = convertedChips[key];
      }
    }

    // Call the product filter API with the updated filteredChips
    try {
      dispatch(postAdminCustomerFilterApi({ filteredData: transformedChips, page, size: currentSize + 10 }));
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  const loadMoreData = useCallback(async () => {
    if (loadingForScrollbar || !hasMoreData) return;
    setLoadingForScrollbar(true);

    const response = await dispatch(postAdminCustomerFilterApi({ filteredData: transformedDataToBeFiltered, page, size: currentSize + 10 }));

    if (response?.meta?.requestStatus === "fulfilled") {
      const newData = response.payload.records;
      if (newData?.length > 0 && newData?.length > currentResultSetLength) {
        setCurrentSize(currentSize + 10);
        setCurrentResultSetLength(newData?.length);
      } else {
        setHasMoreData(false);
      }
    } else {
      errorToast("Error occurred while loading more data");
    }
    setLoadingForScrollbar(false);
  }, [loadingForScrollbar, hasMoreData, currentSize, setCurrentSize, currentResultSetLength, dispatch, page, transformedDataToBeFiltered]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("customerComponent");
      if (scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight) {
        // User has scrolled to the bottom
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("customerComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadMoreData]);

  return (
    <>
      <div className="card">
        <div className="card-header pb-3 pt-3 border-bottom d-flex justify-content-between">
          <h5 className="card-title mb-0">Customers</h5>
          <div className="filter-card" style={{ display: "block" }}>
            {filteredChips.map(([key, value]) => (
              <div className="chip" key={key}>
                <input type="checkbox" name="filters[]" defaultValue="a" defaultChecked="" />
                <span>
                  {chipName(key)} {":"} {key === "carrier_id" || key === "is_active" ? value?.label : value}
                </span>
                <button type="button" className="zap">
                  <i className="ti ti-x fs-6" onClick={() => handleChipRemove(key)} />
                </button>
              </div>
            ))}
          </div>
        </div>
        <InfiniteScroll dataLength={adminCustomerDetails.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : null} scrollableTarget="customerComponent">
          <div id="customerComponent" className="card-datatable table-responsive pb-0" style={{ height: "400px", overflowY: "auto" }}>
            <table className=" table" id="orders">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Country Code</th>
                  <th>Mobile Number</th>
                  <th>Email</th>
                  <th>Carrier</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {adminCustomerDetails?.length !== 0 ? (
                  adminCustomerDetails?.map((adminCustomerDetail, index) => (
                    <tr key={adminCustomerDetail?.id}>
                      <td>{adminCustomerDetail?.name}</td>
                      <td>{adminCustomerDetail?.phone_code}</td>
                      <td>
                        <Link to={`/customer/${adminCustomerDetail?.id}`}>{adminCustomerDetail?.phone_number}</Link>
                      </td>
                      <td>{adminCustomerDetail?.email}</td>
                      <td>{adminCustomerDetail?.carrier_name ?? "--"}</td>
                      <td>
                        <div className="d-flex">
                          <input className="input-switch" type="checkbox" id={`demo-${index}`} checked={adminCustomerDetail?.is_active === 1} onChange={(event) => handleCheckboxChange(event, adminCustomerDetail)} /> <label className="label-switch" htmlFor={`demo-${index}`} /> <span className="info-text ms-2" />{" "}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="pt-3 ps-3">
                    <td colSpan="8">No Customers found</td>
                  </tr>
                )}
                {loadingForScrollbar && (
                  <div className="tr">
                    {" "}
                    <div className="td text">Loading...</div>{" "}
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default CustomersGrid;
