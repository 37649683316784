import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { userForgotPassword } from "../../../services/userService";
import { errorToast, successToast } from "../../Toasts/Toasts";
import ReCAPTCHA from "react-google-recaptcha"; 

function ForgotPassword() {
  const navigate = useNavigate();
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const [captchaValue, setCaptchaValue] = useState(null);

  const onSubmit = async (data) => {
    try {
      if (!captchaValue) {
          errorToast("Please complete the reCAPTCHA verification.");
          return;
      }
      const button = document.getElementById("btnLogin");
      button.disabled = true;
      let obj = {
        userEmailOrPhone : data?.userEmailOrPhone,
        loginType : determineLoginType(location.pathname)
      }
      let response = await userForgotPassword(obj);
      button.disabled = false;

      if (response?.success) {
        successToast(response?.message);
        // navigate("/verify-otp",{ state: { userEmailOrPhone: data?.userEmailOrPhone , loginType:"user" } });
        let verifyOtpPath = "";
        if (location.pathname.startsWith("/customer/forgot-password")) {
          verifyOtpPath = "/customer/verify-otp";
        }
        else{
          verifyOtpPath="/verify-otp"
        }

        // Navigate to the appropriate path
        navigate(verifyOtpPath, { state: { userEmailOrPhone: data?.userEmailOrPhone, loginType: determineLoginType(location.pathname) } });
  
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.message);
    }
  
  };
  
  const determineLoginType = (pathname) => {
    return pathname.startsWith("/customer/forgot-password") ? "customer" : "user";
  };
  const loginHere = (pathname) => {
    return pathname.startsWith("/customer/forgot-password") ? "/" : "/";
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2">
              <div className="card card-topup bg-outline-primary mt-4">
                <div className="d-flex col-12 col-lg-12 align-items-center py-3">
                  <div className="w-px-400 mx-auto">
                    <div className="card-body">
                      <h3 className="mb-5 text-center">Forgot password</h3>
                      <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                          <label htmlFor="userEmailOrPhone" className="form-label">
                            Email /Mobile
                          </label>
                          <input
                            type="text"
                            className={`form-control rounded-pill ${errors.userEmailOrPhone ? "is-invalid" : ""}`}
                            id="userEmailOrPhone"
                            {...register("userEmailOrPhone", {
                              required: "This field is required",
                              validate: (value) => {
                                const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
                                const isPhoneNumber = /^\+\d{1,20}$/.test(value);

                                return isEmail || isPhoneNumber || "Invalid email or Phone Number. For phone numbers, it should start with '+' and contain only digits.";
                              },
                            })}
                            placeholder="Enter your email or mobile"
                          />
                          {errors.userEmailOrPhone && <div className="text-danger">{errors.userEmailOrPhone.message}</div>}{" "}
                        </div>
                        <div className="mb-3">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} 
                          onChange={(value) => setCaptchaValue(value)}
                        />
                      </div>
                        <button id="btnLogin" type="submit" className="btn btn-primary w-100 text-white d-grid rounded-pill">
                          Reset Password
                        </button>
                        <p className="mt-4 mb-0 text-center">
                          Back to <Link to={loginHere(location.pathname)}>Login here</Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
